export const managementConstants = {
	GetUsers: {
		REQUEST : "GET_USERS_REQUEST",
		SUCCESS : "GET_USERS_SUCCESS",
		FAILURE : "GET_USERS_FAILURE"
	},
	GetOrgUsers: {
		REQUEST : "GET_ORG_USERS_REQUEST",
		SUCCESS : "GET_ORG_USERS_SUCCESS",
		FAILURE : "GET_ORG_USERS_FAILURE"
	},
	GetRemovedOrgUsers: {
		REQUEST : "GET_REMOVED_ORG_USERS_REQUEST",
		SUCCESS : "GET_REMOVED_ORG_USERS_SUCCESS",
		FAILURE : "GET_REMOVED_ORG_USERS_FAILURE"
	},
	GetUser: {
		REQUEST : "GET_USER_REQUEST",
		SUCCESS : "GET_USER_SUCCESS",
		FAILURE : "GET_USER_FAILURE"
	},
	GetOrgUser: {
		REQUEST : "GET_ORG_USER_REQUEST",
		SUCCESS : "GET_ORG_USER_SUCCESS",
		FAILURE : "GET_ORG_USER_FAILURE",
		CLEAR   : "GET_ORG_USER_CLEAR"
	},
	Block: {
		REQUEST : "BLOCK_USER_REQUEST",
		SUCCESS : "BLOCK_USER_SUCCESS",
		FAILURE : "BLOCK_USER_FAILURE"
	},
	Unblock: {
		REQUEST : "UNBLOCK_USER_REQUEST",
		SUCCESS : "UNBLOCK_USER_SUCCESS",
		FAILURE : "UNBLOCK_USER_FAILURE"
	},
	GetUserExecut: {
		CLEAR   : "GET_USER_EXECUT_CLEAR",
		REQUEST : "GET_USER_EXECUT_REQUEST",
		SUCCESS : "GET_USER_EXECUT_SUCCESS",
		FAILURE : "GET_USER_EXECUT_FAILURE"
	},
	SetUserExecut: {
		REQUEST : "SET_USER_EXECUT_REQUEST",
		SUCCESS : "SET_USER_EXECUT_SUCCESS",
		FAILURE : "SET_USER_EXECUT_FAILURE"
	},
	GetUserExecutHistory: {
		REQUEST : "GET_USER_EXECUT_HISTORY_REQUEST",
		SUCCESS : "GET_USER_EXECUT_HISTORY_SUCCESS",
		FAILURE : "GET_USER_EXECUT_HISTORY_FAILURE"
	},
	SetTempExternalAdmin: {
		REQUEST : "SET_TEMP_EXTERNAL_ADMIN_REQUEST",
		SUCCESS : "SET_TEMP_EXTERNAL_ADMIN_SUCCESS",
		FAILURE : "SET_TEMP_EXTERNAL_ADMIN_FAILURE"
	},
	UnsetTempExternalAdmin: {
		REQUEST : "UNSET_TEMP_EXTERNAL_ADMIN_REQUEST",
		SUCCESS : "UNSET_TEMP_EXTERNAL_ADMIN_SUCCESS",
		FAILURE : "UNSET_TEMP_EXTERNAL_ADMIN_FAILURE"
	},
	RemoveUserFromReOrg: {
		REQUEST : "REMOVE_USER_FROM_RE_ORG_REQUEST",
		SUCCESS : "REMOVE_USER_FROM_RE_ORG_SUCCESS",
		FAILURE : "REMOVE_USER_FROM_RE_ORG_FAILURE"
	},
	RemoveDisciplinaryActions: {
		REQUEST : "REMOVE_DISCIPLINARY_ACTIONS_REQUEST",
		SUCCESS : "REMOVE_DISCIPLINARY_ACTIONS_SUCCESS",
		FAILURE : "REMOVE_DISCIPLINARY_ACTIONS_FAILURE"
	},
	InviteToReOrg: {
		REQUEST : "INVITE_TO_RE_ORG_REQUEST",
		SUCCESS : "INVITE_TO_RE_ORG_SUCCESS",
		FAILURE : "INVITE_TO_RE_ORG_FAILURE"
	},
	GetInvitations: {
		REQUEST : "GET_INVITATIONS_REQUEST",
		SUCCESS : "GET_INVITATIONS_SUCCESS",
		FAILURE : "GET_INVITATIONS_FAILURE"
	},
	GetInvitation: {
		REQUEST : "GET_INVITATION_REQUEST",
		SUCCESS : "GET_INVITATION_SUCCESS",
		FAILURE : "GET_INVITATION_FAILURE"
	},
	ResponseToInvitation: {
		REQUEST : "RESPONSE_TO_INVITATION_REQUEST",
		SUCCESS : "RESPONSE_TO_INVITATION_SUCCESS",
		FAILURE : "RESPONSE_TO_INVITATION_FAILURE"
	},
	CreateDisciplinaryAction: {
		REQUEST : "CREATE_DISCIPLINARY_ACTION_REQUEST",
		SUCCESS : "CREATE_DISCIPLINARY_ACTION_SUCCESS",
		FAILURE : "CREATE_DISCIPLINARY_ACTION_FAILURE"
	},
	GetDisciplinaryActions: {
		REQUEST : "GET_DISCIPLINARY_ACTIONS_REQUEST",
		SUCCESS : "GET_DISCIPLINARY_ACTIONS_SUCCESS",
		FAILURE : "GET_DISCIPLINARY_ACTIONS_FAILURE"
	},
	ClearDisciplinaryActions: {
		SUCCESS: "CLEAR_DISCIPLINARY_ACTIONS_SUCCESS"
	},
	ChangeRoles: {
		REQUEST : "CHANGE_ROLES_REQUEST",
		SUCCESS : "CHANGE_ROLES_SUCCESS",
		FAILURE : "CHANGE_ROLES_FAILURE"
	},
	AddChildOrg: {
		REQUEST : "ADD_CHILD_ORG_REQUEST",
		SUCCESS : "ADD_CHILD_ORG_SUCCESS",
		FAILURE : "ADD_CHILD_ORG_FAILURE"
	},
	RemoveChildOrg: {
		REQUEST : "REMOVE_CHILD_ORG_REQUEST",
		SUCCESS : "REMOVE_CHILD_ORG_SUCCESS",
		FAILURE : "REMOVE_CHILD_ORG_FAILURE"
	},
	ParentChildOrgs: {
		REQUEST : "PARENT_CHILD_ORGS_REQUEST",
		SUCCESS : "PARENT_CHILD_ORGS_SUCCESS",
		FAILURE : "PARENT_CHILD_ORGS_FAILURE"
	},
	InstitutionChildOrgs: {
		REQUEST : "INSTITUTION_CHILD_ORGS_REQUEST",
		SUCCESS : "INSTITUTION_CHILD_ORGS_SUCCESS",
		FAILURE : "INSTITUTION_CHILD_ORGS_FAILURE"
	},
	GetChildInfo: {
		REQUEST : "CHILD_ORGS_INFO_REQUEST",
		SUCCESS : "CHILD_ORGS_INFO_SUCCESS",
		FAILURE : "CHILD_ORGS_INFO_FAILURE"
	},
	GetChildOrgInvitations: {
		REQUEST : "GET_CHILD_ORG_INVITATIONS_REQUEST",
		SUCCESS : "GET_CHILD_ORG_INVITATIONS_SUCCESS",
		FAILURE : "GET_CHILD_ORG_INVITATIONS_FAILURE"
	},
	GetChildOrgInvitation: {
		REQUEST : "GET_CHILD_ORG_INVITATION_REQUEST",
		SUCCESS : "GET_CHILD_ORG_INVITATION_SUCCESS",
		FAILURE : "GET_CHILD_ORG_INVITATION_FAILURE"
	},
	ResponseToChildOrgInvitation: {
		REQUEST : "RESPONSE_TO_CHILD_ORG_INVITATION_REQUEST",
		SUCCESS : "RESPONSE_TO_CHILD_ORG_INVITATION_SUCCESS",
		FAILURE : "RESPONSE_TO_CHILD_ORG_INVITATION_FAILURE"
	},
	GetOrganizationUnits: {
		REQUEST : "GET_ORGANIZATION_UNITS_REQUEST",
		SUCCESS : "GET_ORGANIZATION_UNITS_SUCCESS",
		FAILURE : "GET_ORGANIZATION_UNITS_FAILURE",
		CLEAR   : "CLEAR_ORGANIZATION_UNITS"
	},
	ChangeOrganizationUnit: {
		REQUEST : "GHANGE_ORGANIZATION_UNIT_REQUEST",
		SUCCESS : "GHANGE_ORGANIZATION_UNIT_SUCCESS",
		FAILURE : "GHANGE_ORGANIZATION_UNIT_FAILURE"
	},
	DeleteRestoreOrganizationUnit: {
		REQUEST : "DELETERESTORE_ORGANIZATION_UNIT_REQUEST",
		SUCCESS : "DELETERESTORE_ORGANIZATION_UNIT_SUCCESS",
		FAILURE : "DELETERESTORE_ORGANIZATION_UNIT_FAILURE"
	},
	GetSubunitOrgsList: {
		REQUEST : "GET_SUBUNIT_ORGS_LIST_REQUEST",
		SUCCESS : "GET_SUBUNIT_ORGS_LIST_SUCCESS",
		FAILURE : "GET_SUBUNIT_ORGS_LIST_FAILURE",
		CLEAR  	: "CLEAR_SUBUNIT_ORGS_LIST"
	},
	ReassignOrgUser: {
		REQUEST : "REASSIGN_ORG_USER_REQUEST",
		SUCCESS : "REASSIGN_ORG_USER_SUCCESS",
		FAILURE : "REASSIGN_ORG_USER_FAILURE"
	},
	DeleteUserIdentificationNumber: {
		REQUEST : "DELETE_USER_IDENTIFICATION_NUMBER_REQUEST",
		SUCCESS : "DELETE_USER_IDENTIFICATION_NUMBER_SUCCESS",
		FAILURE : "DELETE_USER_IDENTIFICATION_NUMBER_FAILURE",
		CLEAR  	: "CLEAR_DELETE_USER_IDENTIFICATION_NUMBER_REDUCE"
	},
	ChangeOrgUnitToOrg: {
		REQUEST : "CHANGE_ORG_UNIT_TO_ORG_REQUEST",
		SUCCESS : "CHANGE_ORG_UNIT_TO_ORG_SUCCESS",
		FAILURE : "CHANGE_ORG_UNIT_TO_ORG_FAILURE",
		CLEAR  	: "CLEAR_CHANGE_ORG_UNIT_TO_ORG_REDUCE"
	},
	ExportRegisteredReOrgsFormExcel: {
		REQUEST : "EXPORT_REGISTERED_ORGS_TO_EXCEL_REQUEST",
		SUCCESS : "EXPORT_REGISTERED_ORGS_TO_EXCEL_SUCCESS",
		FAILURE : "EXPORT_REGISTERED_ORGS_TO_EXCEL_FAILURE"
	},
	SetExternalAdminToSelfRegistratedJurdical: {
		REQUEST : "SET_EXTERNAL_ADMIN_TO_SELF_REGISTRATED_JURDICAL_REQUEST",
		SUCCESS : "SET_EXTERNAL_ADMIN_TO_SELF_REGISTRATED_JURDICAL_SUCCESS",
		FAILURE : "SET_EXTERNAL_ADMIN_TO_SELF_REGISTRATED_JURDICAL_FAILURE",
		CLEAR	: "SET_EXTERNAL_ADMIN_TO_SELF_REGISTRATED_JURDICAL_CLEAR"
	}
}